import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'react-select2-wrapper';
import FieldLabel from './FieldLabel';
import Description from './Description';
import TextCounter from './TextCounter';
import TextInputValidator from './TextInputValidator';

export default function OptionField({ field, prohibitedCharacters, showLabel, initialValue, updateFieldValue }) {
  // Should these two be part of the same state?
  const [value, setValue] = useState(initialValue);
  const [query, setQuery] = useState(initialValue);

  const textValidator = new TextInputValidator(field.text_limit, field.expanded_allowed_characters, prohibitedCharacters);

  const localHandleChange = (event) => {
    var value = event.target.value;
    setValue(value);
    setQuery(value);
    updateFieldValue(field.id, value);
  }

  // Use useCallback?
  const optionValuesData = () => {
    var valueIsInOptions = false;
    var values = [];

    for (let optionValue of field.option_values) {
      if (initialValue === optionValue.name) {
        valueIsInOptions = true;
      }
      // data-description is used for terminus.select2DescriptionTemplate
      values.push({
        id: optionValue.name,
        text: optionValue.name,
        title: optionValue.description,
        'data-description': optionValue.description
      });
    }

    // When a value (that's not one of option values) is initalized,
    // it can be blank if not part of options values. So we add it here.
    // Side note: If an initial value is initalized that is not in option values,
    // and a new value is selected from the dropdown, the original initial value is
    // completedly removed from the list (This behavior is ok for now)
    if (!valueIsInOptions && field.allow_new_option_values) {
      values.push({ id: initialValue, text: initialValue });
    }

    return values;
  }

  const createTag = useCallback(params => {
    let newQuery = params.term;

    newQuery = textValidator.validate(newQuery);

    if (newQuery === '') {
      return null;
    }

    setQuery(newQuery)

    return {
      id: newQuery,
      text: newQuery
    }
  }, [])

  let placeholder = "Select";

  if (field.allow_new_option_values) {
    placeholder += "/type";
  }

  placeholder += " a " + field.name;

  let textCounterNode;

  if (field.allow_new_option_values) {
    textCounterNode = (
      <div className="pull-right">
        <TextCounter maxCount={field.text_limit} currentValue={query} />
      </div>
    )
  }

  // NOTE:
  // 1. Don't use onChange on Select2. onSelect works just fine
  // https://github.com/rkit/react-select2-wrapper/issues/63
  //
  // 2. Don't define Select2 functions in render. They add event listeners and
  // you don't want them to be added on each render. UI can get really slow
  return (
    <div>
      <FieldLabel field={field} showLabel={showLabel} />
      {textCounterNode}

      <Select2
        id={`field-${field.id}`}
        value={value}
        data={optionValuesData()}
        onSelect={localHandleChange}
        onClose={localHandleChange}
        options={
          {
            width: '100%',
            placeholder: placeholder,
            templateResult: terminus.select2DescriptionTemplate,
            templateSelection: terminus.select2DescriptionTemplate,
            matcher: terminus.select2TitleMatcher,
            allowClear: true,
            tags: field.allow_new_option_values,
            createTag: createTag
          }
        }
      />
      <Description field={field} />
    </div>
  )
}

OptionField.propTypes = {
  field: PropTypes.object.isRequired,
  prohibitedCharacters: PropTypes.array.isRequired,
  showLabel: PropTypes.bool.isRequired,
  initialValue: PropTypes.string,
  updateFieldValue: PropTypes.func.isRequired
}