import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import FieldLabel from './FieldLabel';
import Description from './Description';

export default function DateField({ field, showLabel, initialValue, updateFieldValue }) {
  const [momentStartDate, setMomentStartDate] = useState(() => {
    if (initialValue === null || initialValue === undefined || initialValue === "") {
      return moment();
    } else {
      return moment(initialValue, field.date_format);
    }
  });

  // TODO: It may make sense to not auto-fill the date value unless there is an initial value
  // to be consistent with all the other types of fields.
  useEffect(() => {
    if (field.required) {
      updateFieldValue(field.id, formattedDate(momentStartDate));
    }
  }, []); // Run only once on initial render.

  const formattedDate = (momentDate) => {
    return momentDate.format(field.date_format);
  }

  const localHandleChange = (event, picker) => {
    var startDate = picker.startDate;
    setMomentStartDate(startDate);
    updateFieldValue(field.id, formattedDate(startDate));
  }

  return (
    <div>
      <FieldLabel field={field} showLabel={showLabel} />
      <div className='input-group'>
        <span className='input-group-addon'>
          <i className='icon-calendar22'></i>
        </span>
        <DateRangePicker initialSettings={{
          locale: { format: "YYYY/MM/DD" },
          startDate: momentStartDate,
          singleDatePicker: true,
          showWeekNumbers: true
        }}
          onApply={localHandleChange}>
          <input id={`field-${field.id}`} className='form-control' type='text' />
        </DateRangePicker>
        <span className='input-group-addon'>
          <span className="text-muted pl-5">{formattedDate(momentStartDate)}</span>
        </span>
      </div>
      <Description field={field} />
    </div>
  )
}

DateField.propTypes = {
  field: PropTypes.object.isRequired,
  showLabel: PropTypes.bool.isRequired,
  initialValue: PropTypes.string,
  updateFieldValue: PropTypes.func.isRequired
}