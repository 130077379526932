import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc';

import guid from './shared/guid';
import OptionValue from './fields/OptionValue';

const DragHandle = SortableHandle(() => {
  return (
    <div className="col-xs-1" style={{ width: "30px" }}>
      <i className="icon-three-bars text-muted pt-10 cursor-move " title="Reorder"></i>
    </div>
  );
})

const SortableItem = SortableElement(({ idx, onRemove, onNameChange, onDescriptionChange, optionValue }) => {
  return (
    <div className="option-value row mb-10" style={{ display: optionValue.markedForDistruction ? "none" : "block" }}>
      <DragHandle />
      <OptionValue
        index={idx}
        optionValue={optionValue}
        onRemove={onRemove}
        onNameChange={onNameChange}
        onDescriptionChange={onDescriptionChange}
      />
    </div>
  )
})

const SortableList = SortableContainer(({ optionValues, onRemove, onNameChange, onDescriptionChange }) => {
  return (
    <div>
      {optionValues.map((optionValue, index) =>
        <SortableItem
          key={optionValue.id || optionValue.key}
          index={index}
          idx={index}
          optionValue={optionValue}
          onRemove={onRemove}
          onNameChange={onNameChange}
          onDescriptionChange={onDescriptionChange}
        />
      )}
    </div>
  );
})

class OptionValuesFields extends React.Component {
  static propTypes = {
    initialOptionValues: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      optionValues: this.props.initialOptionValues,
      bulkUpdateMode: false
    }
  }

  // Required for OptionValue tooltips
  componentDidMount() {
    $('.tool-tip').tooltip({ container: 'body' });
  }

  render() {

    let dragHandleWidthNode = (
      <div className="col-xs-1" style={{ width: "30px" }}></div>
    )

    let errorNode;
    if (this.hasDuplicateOptionValues()) {
      errorNode = (
        <div className="row mb-10">
          {dragHandleWidthNode}
          <div className="col-xs-11">
            <span className='text-danger'>Duplicate values found. Please make sure all values are unique</span>
          </div>
        </div>
      )
    }

    let normalModeNode = (
      <div>
        <div className="row">
          {dragHandleWidthNode}
          <div className="col-xs-11">
            <ul className="list-inline">
              <li>
                <a onClick={this.handleSortOptionValue}>
                  <i className="icon-sort-alpha-asc position-left"></i>
                  Sort
                </a>
              </li>
              <li>
                <a onClick={this.handleBulkUpdateMode}>
                  <i className="icon-pencil3 position-left"></i>
                  Bulk update
                </a>
              </li>
            </ul>
          </div>
        </div>

        <SortableList
          optionValues={this.state.optionValues}
          onSortEnd={this.onSortEnd}
          onRemove={this.handleRemoveOptionValue}
          onNameChange={this.handleOptionValueNameChange}
          onDescriptionChange={this.handleOptionValueDescriptionChange}
          useDragHandle={true}
          lockAxis="y"
        />

        {errorNode}

        <div className="row">
          {dragHandleWidthNode}
          <div className="col-xs-11">
            <ul className="list-inline">
              <li>
                <a onClick={this.handleAddOptionValue}>
                  <i className="icon-plus22 position-left"></i>
                  Add value
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )

    let bulkUpdateWarning;

    if (this.hasChildFields()) {
      bulkUpdateWarning = (
        <div className="row mt-10">
          <div className="col-xs-12">
            <span className="text-warning">
              <span className="text-bold">BE CAREFUL : </span>
              One or more option values have dependent fields. Removing or changing them can break this dependency.
            </span>
          </div>
        </div>
      )
    }

    let bulkUpdateNode = (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <textarea
              id="options-bulk-update-textbox"
              className="form-control"
              rows="15"
              value={this.state.bulkUpdateValues}
              onChange={this.handleBulkUpdateValuesChange}
            />
          </div>
        </div>

        {bulkUpdateWarning}

        <div className="row mt-10">
          <div className="col-xs-12">
            <ul className="list-inline">
              <li>
                <a onClick={this.handleBulkUpdateValues}>
                  <i className="icon-pencil3 position-left"></i>
                  Update values
                </a>
              </li>
              <li>
                <a onClick={this.handleBulkUpdateCancel}>
                  <i className="icon-cross3 position-left"></i>
                  Cancel
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )

    let optionsListNode = this.state.bulkUpdateMode ? bulkUpdateNode : normalModeNode;

    return (
      <div>
        {optionsListNode}
      </div>
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      { optionValues: arrayMove(this.state.optionValues, oldIndex, newIndex) },
      this.recalculatePositions
    );
  }

  // NOTE: Use this syntax '= () =>' for callback handlers since it binds
  // 'this' correctly to this class.
  // Refer: https://reactjs.org/docs/handling-events.html
  handleRemoveOptionValue = (index) => {
    let newOptionValues = update(
      this.state.optionValues, { [index]: { markedForDistruction: { $set: true } } }
    )

    this.setState({ optionValues: newOptionValues }, this.recalculatePositions)
  }

  handleOptionValueNameChange = (index, value) => {
    let newOptionValues = update(
      this.state.optionValues, { [index]: { name: { $set: value } } }
    )

    this.setState({ optionValues: newOptionValues })
  }

  handleOptionValueDescriptionChange = (index, value) => {
    let newOptionValues = update(
      this.state.optionValues, { [index]: { description: { $set: value } } }
    )

    this.setState({ optionValues: newOptionValues })
  }

  handleAddOptionValue = () => {
    let newOptionValues = update(
      this.state.optionValues, { $push: [{ key: guid(), name: "", description: "", markedForDistruction: false }] }
    )

    this.setState({ optionValues: newOptionValues }, this.recalculatePositions)
  }

  handleSortOptionValue = () => {
    let newOptionValues = update(this.state.optionValues, {});
    //https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
    newOptionValues.sort((a, b) => a.name.localeCompare(b.name));

    this.setState({ optionValues: newOptionValues }, this.recalculatePositions)
  }

  recalculatePositions = () => {
    let positionCounter = 0;

    // NOTE: Don't use visibleOptionValues here since newOptionValues should represent even markedForDistruction items
    let newOptionValues = this.state.optionValues.map((optionValue, index) => {
      if (!optionValue.markedForDistruction) {
        positionCounter++;
      }

      optionValue.position = positionCounter;
      return optionValue;
    });

    this.setState({ optionValues: newOptionValues });
  }

  handleBulkUpdateMode = () => {
    this.setState({ bulkUpdateMode: true, bulkUpdateValues: this.initialBulkUpdateValues() })
  }

  initialBulkUpdateValues = () => {
    let initialValue = "";

    for (let optionValue of this.visibleOptionValues()) {
      initialValue += optionValue.name + "," + optionValue.description + "\n";
    }
    return initialValue;
  }

  handleBulkUpdateCancel = () => {
    this.setState({ bulkUpdateMode: false })
  }

  handleBulkUpdateValues = () => {
    const self = this;

    let newOptionValues = this.markAllOptionValuesForDestruction();

    const parsedOptionValues = this.parsedBulkUpdateOptionValues();

    for (let [index, parsedOptionValue] of parsedOptionValues.entries()) {
      let existingValueIndex = self.findOptionValueByName(newOptionValues, parsedOptionValue.name);
      let existingOptionValue = newOptionValues[existingValueIndex];

      if (typeof existingValueIndex === 'number' && existingOptionValue.markedForDistruction) {
        newOptionValues = update(newOptionValues, { [existingValueIndex]: { markedForDistruction: { $set: false }, description: { $set: parsedOptionValue.description } } })
        newOptionValues = arrayMove(newOptionValues, existingValueIndex, index)
      } else {
        if (parsedOptionValue.name.length > 0) {
          let newOptionValue = { key: guid(), name: parsedOptionValue.name, description: parsedOptionValue.description, markedForDistruction: false };
          // Insert it into the appropriate location
          newOptionValues = update(newOptionValues, { $splice: [[index, 0, newOptionValue]] })
        }
      }
    }

    this.setState({ optionValues: newOptionValues, bulkUpdateMode: false }, this.recalculatePositions)
  }

  markAllOptionValuesForDestruction = () => {
    let newOptionValues = update(this.state.optionValues, {});

    for (let index = 0; index < this.state.optionValues.length; index++) {
      newOptionValues = update(newOptionValues, { [index]: { markedForDistruction: { $set: true } } })
    }

    return newOptionValues;
  }

  findOptionValueByName = (optionValues, name) => {
    for (let [index, optionValue] of optionValues.entries()) {
      if (optionValue.name == name) {
        return index;
      }
    }
  }

  parsedBulkUpdateOptionValues = () => {
    const rows = this.state.bulkUpdateValues.split(/\n/);

    let parsedOptionValues = [];

    for (let row of rows) {
      let attributes = row.split(",");
      let name = (attributes[0] || "").trim();
      let description = (attributes[1] || "").trim();

      if (name.length > 0) {
        parsedOptionValues.push({ name: name, description: description })
      }
    }

    return parsedOptionValues;
  }

  handleBulkUpdateValuesChange = (event) => {
    this.setState({ bulkUpdateValues: event.target.value })
  }

  hasDuplicateOptionValues = () => {
    const self = this;
    const names = this.visibleOptionValues().map(function (item) { return item.name });

    const duplicateValues = names.filter(function (item, index) {
      const duplicate = names.indexOf(item) !== index;
      self.visibleOptionValues()[index].duplicate = duplicate;
      return duplicate;
    });

    if (duplicateValues.length > 0) {
      document.getElementById('save-field').disabled = true;
    } else {
      document.getElementById('save-field').disabled = false;
    }

    return duplicateValues.length > 0;
  }

  hasChildFields = () => {
    const hasChildren = this.visibleOptionValues().some(function (optionValue, idx) {
      return optionValue.child_fields && optionValue.child_fields.length > 0
    });
    return hasChildren;
  }

  visibleOptionValues = () => {
    return this.state.optionValues.filter(function (item) { return item && !item.markedForDistruction });
  }
};

export default OptionValuesFields;
