import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldLabel from './FieldLabel';
import TextCounter from './TextCounter';
import TextInputValidator from './TextInputValidator';
import Description from './Description';
import { multiLineTextToArray, longestString } from "../shared/StringUtilities";

export default function TextField({
  field,
  showLabel,
  initialValue,
  updateFieldValue,
  updateMultipleFieldValues,
  prohibitedCharacters,
  allowMultipleFieldValues }) {

  const [value, setValue] = useState(initialValue || '');
  const [rawMultipleValues, setRawMultipleValues] = useState(initialValue || '');
  const [arrayMultipleValues, setArrayMultipleValues] = useState([])

  const textValidator = new TextInputValidator(field.text_limit, field.expanded_allowed_characters, prohibitedCharacters);

  const localHandleChange = (event) => {
    let value = event.target.value;
    value = textValidator.validate(value);
    setValue(value)
    updateFieldValue(field.id, value);
  }

  const localHandleMultipleValuesChange = (event) => {
    let values = event.target.value;
    let arrayValues = multiLineTextToArray(values);

    for (const [index, value] of arrayValues.entries()) {
      arrayValues[index] = textValidator.validate(value);
    }

    setRawMultipleValues(values);
    setArrayMultipleValues(arrayValues);
    updateMultipleFieldValues(field.id, arrayValues);
  }


  let inputNode;

  if (allowMultipleFieldValues) {
    inputNode = (
      <textarea
        id={`field-${field.id}`}
        className="form-control"
        value={rawMultipleValues}
        onChange={localHandleMultipleValuesChange}
        placeholder="Enter a value on each line"
      />
    )
  } else {
    inputNode = <input id={`field-${field.id}`} className="form-control" type="text" value={value} onChange={localHandleChange} />
  }

  let textCounterValue;

  if (allowMultipleFieldValues) {
    textCounterValue = longestString(arrayMultipleValues);
  } else {
    textCounterValue = value;
  }

  return (
    <div>
      <FieldLabel field={field} showLabel={showLabel} />
      <div className="pull-right">
        <TextCounter maxCount={field.text_limit} currentValue={textCounterValue} />
      </div>
      {inputNode}
      <Description field={field} />
    </div>
  )
}

TextField.propTypes = {
  field: PropTypes.object.isRequired,
  showLabel: PropTypes.bool.isRequired,
  initialValue: PropTypes.string,
  updateFieldValue: PropTypes.func.isRequired,
  updateMultipleFieldValues: PropTypes.func.isRequired,
  prohibitedCharacters: PropTypes.array.isRequired,
  allowMultipleFieldValues: PropTypes.bool.isRequired
}